<template>
  <div id="footer">
    <b-card
      no-body
      class="bg-transparent"
    >
      <div class="py-50 px-1 p-md-1 d-flex-between">
        <div v-if="isMobileView">
          <b-button
            variant="warning"
            pill
            class="p-50 py-sm-1 px-sm-2 text-nowrap d-flex-center gap-1"
            @click="$root.$emit('bv::show::modal', 'modal-result-flightv2-search')"
          >
            <span v-if="this.$store.state.app.windowWidth >= 400">{{ $t('flight.flight') }}</span>
            <b-img
              v-if="this.$store.state.app.windowWidth < 400 || this.$store.state.app.windowWidth >= 500"
              class="airlines-logo px-0"
              blank-color="#ccc"
              style="height: 18px;"
              :src="require(`@icons/airplane-white.svg`)"
            />
          </b-button>
        </div>
        <div
          :class="`d-flex-center ${!isMobileView ? 'w-100' : ''}`"
          style="column-gap: 10px"
        >
          <b-button
            variant="outline-info"
            pill
            :disabled="disabledCopyPrice"
            class="p-50 py-sm-1 px-sm-2 text-nowrap"
            @click="$root.$emit('bv::show::modal', 'id-modal-quote-on-booking-search')"
          >
            {{ $t('flight.copyPrice') }}
          </b-button>

          <b-button
            v-if="!isRoleF3"
            variant="gradient"
            class="p-50 py-sm-1 px-sm-2 text-nowrap"
            :disabled="!selectedTrip[tabIndex]"
            pill
            @click="handleShowBookGroup"
          >
            {{ $t('flight.groupBooking.calculate') }}
          </b-button>

          <b-button
            variant="gradient"
            pill
            class="p-50 py-sm-1 px-sm-2 text-nowrap"
            :disabled="!selectedTrip[tabIndex] && !combinationSelectedTrip"
            @click="handleClick"
          >
            <div v-if="tabIndexNotSelect < 0 || selectedTrip.length === 1 || combinationSelectedTrip">
              {{ $t('flight.submit') }}
            </div>
            <div v-else>
              {{ $t('flight.nextFlight') }}
            </div>
          </b-button>
        </div>
      </div>
    </b-card>

    <ModalWarningGoToReservation
      @confirm="gotoReservation"
    />

    <ModalInfoCreateGroupBooking
      v-if="tabIndexNotSelect === -1"
      :selected-trip="draftSelectedTrip"
      @goToReservation="handleClick"
    />

  </div>
</template>
<script>
import { BCard, BButton, BImg } from 'bootstrap-vue'
import { toRefs, computed } from '@vue/composition-api'
import Vue from 'vue'

import { useRouter } from '@/@core/utils/utils'
import store from '@/store'
import { distributorExtendOptions } from '@/constants/selectOptions'

import {
  formatCurrency,
} from '@core/utils/filter'

import useBookingHandle from '@flightv2/useBookingHandle'

import useToast from '@useToast'

export default {
  components: {
    BCard,
    BButton,
    BImg,
    ModalWarningGoToReservation: () => import('./ModalWarningGoToReservation.vue'),
    ModalInfoCreateGroupBooking: () => import('./components/ModalInfoCreateGroupBooking.vue'),
  },
  props: {
    selectedTrips: {
      type: Array,
      default: () => [],
    },
    disabledCopyPrice: {
      type: Boolean,
      default: true,
    },

  },
  setup(props, { root }) {
    const { toastError } = useToast()

    const { router } = useRouter()
    const { tabIndex } = useBookingHandle()
    const selectedTrip = toRefs(props).selectedTrips
    const agencyData = computed(() => store.getters['userStore/getAgencyData'])
    const tabIndexNotSelect = computed(() => selectedTrip.value.findIndex(item => item === null))
    const draftSelectedTrip = computed(() => store.getters['app-flight-v2/getDraftSelectedTrip'])
    const isRoleF3 = computed(() => store.getters['userStore/getRoleCTV'])
    const isRoleF2 = computed(() => store.getters['userStore/getRoleF2'])

    function handleShowBookGroup() {
      if (tabIndexNotSelect.value !== -1) {
        toastError({ title: this.$t('flight.groupBooking[\'Please select all itineraries!\']') })
        return
      }

      if (!draftSelectedTrip.value.every(trip => trip?.domestic)) {
        toastError({ title: this.$t('flight.groupBooking[\'Group pricing only supports domestic trips!\']') })
        return
      }

      if (draftSelectedTrip.value.some(trip => ['VN1A'].includes(trip.source))) {
        toastError({ title: this.$t('flight.groupBooking[\'Group pricing only supports domestic trips!\']') })
        return
      }

      this.$bvModal.show('modal-flightv2-show-price-group-booking')
    }

    function checkTimeMultTrips(trips) {
      for (let i = 1; i < trips.length; i += 1) {
        const departureTime = new Date(trips[i].departure.at)
        const arrivalTime = new Date(trips[i - 1].arrival.at)
        if (departureTime < arrivalTime) {
          return 'red'
        }
        const totalTime = departureTime - arrivalTime
        const hoursToCheck = totalTime / (1000 * 60 * 60) // 2h
        if (hoursToCheck >= 2) {
          return 'green'
        }
        return 'yellow'
      }
      return 'green'
    }

    const gotoReservation = () => {
      store.dispatch('app-flight-v2/setSelectedTrip', selectedTrip.value)
      router.push({
        name: 'apps-flightsV2-reservation',
      })
    }

    const combinationSelectedTrip = computed(() => store.getters['app-flight-v2/getCombinationSelectedTrip'])
    function handleClick() {
      if (isRoleF2.value || isRoleF3.value) {
        let limitedTrip = false
        if (combinationSelectedTrip.value) {
          const isLimited = agencyData.value.bookingLimit.some(limit => limit.airlineSource === combinationSelectedTrip.value?.source && limit.enableBooking === false)
          limitedTrip = isLimited ? combinationSelectedTrip.value : false
        } else {
          limitedTrip = draftSelectedTrip.value
            .filter(Boolean)
            .find(trip => agencyData.value.bookingLimit.some(limit => limit.airlineSource === trip?.source && limit.enableBooking === false))
        }
        const distributor = distributorExtendOptions.find(option => option.value === limitedTrip?.source)
        if (limitedTrip && distributor) {
          Vue.swal({
            title: this.$t('Warning'),
            text: this.$t('flight[\'You do not have permission to book airline tickets. Please contact the managing agent to book tickets!\']', { airline: distributor.label }),
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-danger',
            },
          })
          return
        }
      }

      if (combinationSelectedTrip.value) {
        gotoReservation()
        return
      }
      if (tabIndexNotSelect.value >= 0) {
        store.dispatch('app-flight-v2/setTabIndex', tabIndexNotSelect.value)
      } else {
        const checkHours = selectedTrip.value.length < 2 ? 'green' : checkTimeMultTrips(selectedTrip.value)
        if (checkHours === 'green') {
          gotoReservation()
        } else if (checkHours === 'yellow') {
          const id = 'modal-warning-go-to-reservation-mult-trip'
          root.$root.$emit('bv::show::modal', id)
        } else if (checkHours === 'red') {
          toastError({
            title: this.$t('flight[\'Departure time must always be after the end time of the previous journey!\']'),
          })
        }
      }
    }

    return {
      selectedTrip,
      handleClick,
      formatCurrency,
      tabIndexNotSelect,
      tabIndex,
      gotoReservation,
      handleShowBookGroup,
      draftSelectedTrip,
      isRoleF3,
      combinationSelectedTrip,
    }
  },
}
</script>
<style lang="scss" scoped>
#footer ::v-deep {
  position: sticky;
  bottom: 0;
  z-index: 10;

  .bg-transparent {
    background: rgba(255, 255, 255, 0.8);
    backdrop-filter: blur(5px);
  }

     // width < sm
  @media (max-width: 576px) {
    bottom: 10px;
  }
}
</style>
